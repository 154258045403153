import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useModal from 'antd/lib/modal/useModal';
import styled from 'styled-components';
import { ReactComponent as BroadcastCreatedIllustration } from 'src/assets/images/broadcast-created-illustration.svg';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';

const StyledIllustration = styled(BroadcastCreatedIllustration)`
  margin-bottom: 15px;

  svg {
    height: 200px;
    width: 200px;
  }
`;

const Message = styled.p`
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 26px;
  line-height: 34px;
  letter-spacing: -0.5199999809265137px;
  text-align: center;
  color: ${({ theme }) => theme.colors.smalt};
  margin-bottom: 5px;
  max-width: 325px;
`;

const MessageContent = styled(FlexColumn)`
  align-items: center;
`;

const CloseIcon = styled(Icon)`
  svg {
    path {
      fill: ${({ theme }) => theme.colors.geyser};
    }
  }
`;

export type BroadcastMessageContextProps = {
  showBroadcastMessage: (type: 'CREATED' | 'SCHEDULED') => void;
};

export const BroadcastMessageContext = React.createContext<BroadcastMessageContextProps>({
  showBroadcastMessage: () => {},
});

type BroadcastMessageManagerProps = {
  children: React.ReactNode;
};

export const BroadcastMessageManager = memo(
  ({ children }: BroadcastMessageManagerProps) => {
    const [messageToShow, setMessageToShow] = useState<'CREATED' | 'SCHEDULED' | null>(
      null,
    );
    const [modal, modalContext] = useModal();
    const { t } = useTranslation(['broadcast']);

    useEffect(() => {
      if (messageToShow) {
        modal.info({
          className: 'broadcast-creation-message',
          icon: null,
          closable: true,
          closeIcon: <CloseIcon type="close" />,
          okButtonProps: {
            disabled: true,
          },
          onCancel: () => {
            setMessageToShow(null);
          },
          centered: true,
          cancelButtonProps: {
            disabled: true,
          },
          style: {
            width: '460px',
          },
          maskClosable: true,
          content: (
            <MessageContent
              data-lgg-id={`broadcast-creation-message-${
                messageToShow === 'SCHEDULED' ? `scheduled` : 'created'
              }`}
            >
              <StyledIllustration />
              <Message>
                {t(
                  messageToShow === 'CREATED'
                    ? 'broadcast:pages.broadcast.broadcastMessages.created.title'
                    : 'broadcast:pages.broadcast.broadcastMessages.scheduled.title',
                )}
              </Message>
            </MessageContent>
          ),
        });
      }
    }, [messageToShow, modal, t]);

    return (
      <BroadcastMessageContext.Provider
        value={{
          showBroadcastMessage: (type) => {
            setMessageToShow(type);
          },
        }}
      >
        {children}
        {modalContext}
      </BroadcastMessageContext.Provider>
    );
  },
);
