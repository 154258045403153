import { gql } from '@apollo/client';
import { CORE_PAGE_INFO_FIELDS } from 'src/components/providers/apollo-provider-provider';

export const CREATE_BROADCAST_MUTATION = gql`
  mutation CreateBroadcast($input: CreateBroadcastInput!) {
    createBroadcast(input: $input) {
      id
      name
      status
      scheduledAt
      audience
    }
  }
`;

export const GET_BROADCAST_LIST = gql`
  ${CORE_PAGE_INFO_FIELDS}
  query Broadcasts(
    $companyId: Int!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $where: BroadcastListWhereInput
    $orderBy: BroadcastListOrderByInput
  ) {
    broadcasts(
      companyId: $companyId
      after: $after
      before: $before
      first: $first
      last: $last
      where: $where
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      nodes {
        id
        name
        status
        startedAt
        isArchived
        optOuts
        replies
        sent
        channelsConfiguration {
          ... on BroadcastChannelSmsConfiguration {
            resource {
              phoneNumber {
                national
              }
            }
            content {
              ... on BroadcastChannelSmsContentMessage {
                message
                type
              }
            }
          }
        }
      }
    }
  }
`;
