import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import c from 'classnames';
import styled, { css } from 'styled-components';
import { match, P } from 'ts-pattern';
import { Broadcast, BroadcastStatus } from '@lgg/isomorphic/types/__generated__/graphql';
import { LggOptionsDropdownButtonWithCustomTriggerControlled } from 'src/components/general/button/dropdown-button';
import { Table, TableColumns, TableProps } from 'src/components/general/display/table';
import { Icon } from 'src/components/general/icon';
import { getDefaultSortOrder, TableSortData } from 'src/components/general/table-helpers';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { useFormatDate } from 'src/hooks/use-format-date';

const baseTagStyles = css`
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 8px;
  height: 12px;
  letter-spacing: -0.16px;
  line-height: 10px;
  margin: 3px 0 0;
  padding: 0 4px;
  text-transform: uppercase;
  width: max-content;
`;

const BroadcastStatusTag = styled(({ tagTheme, ...props }) => <Tag {...props} />)`
  ${baseTagStyles};
  background: ${({ theme, tagTheme }) => theme.colors[tagTheme.background]};
  border-color: ${({ theme, tagTheme }) => theme.colors[tagTheme.border]};
  border-radius: 2px;
  color: ${({ theme, tagTheme }) => theme.colors[tagTheme.color]};
`;

const BroadcastArchivedTag = styled(Tag)`
  ${baseTagStyles};
  background: ${({ theme }) => theme.colors.white};
  border-color: ${({ theme }) => theme.colors.geyser};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.flint};
  margin-left: 3px;
`;

const BroadcastDescriptionContainer = styled(FlexColumn)`
  width: max-content;
`;

const BroadcastTitle = styled.p`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  line-height: 16px;
  margin: 0;
  text-align: left;
`;

const TableBoldText = styled.span`
  color: ${({ theme }) => theme.colors.raven};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;

  &.success {
    color: ${({ theme }) => theme.colors.secondaryMintDark};
  }

  &.good {
    color: ${({ theme }) => theme.colors.secondaryGoldDark};
  }

  &.bad {
    color: ${({ theme }) => theme.colors.secondaryCoralDark};
  }
`;

const TableLightText = styled.span`
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: ${({ theme }) => theme.colors.flint};
`;

const RowInfoContainer = styled(FlexColumn)`
  ${TableBoldText} {
    margin-bottom: 4px;
  }
`;

const ColumnHeaderText = styled.span`
  -ms-user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  user-select: none;
`;

const shouldShowBroadcastDetail = (status: BroadcastStatus) => {
  return match(status)
    .with(
      P.union('IN_PROGRESS_PREPARING', 'IN_PROGRESS_SENDING', 'CANCELED', 'SENT'),
      () => true,
    )
    .otherwise(() => false);
};

const emptyPlaceholder = <TableLightText>–</TableLightText>;

const getPercentageLabel = (
  total: number,
  value: number,
  addValueColor: boolean = false,
) => {
  const percentage = total > 0 ? (value / total) * 100 : 0;

  const className = addValueColor
    ? match(percentage)
        .when(
          (percentage) => percentage < 3,
          () => 'success',
        )
        .when(
          (percentage) => percentage < 5,
          () => 'good',
        )
        .when(
          (percentage) => percentage >= 5,
          () => 'bad',
        )
        .otherwise(() => undefined)
    : undefined;

  return (
    <TableBoldText
      className={className ? c({ [className]: true }) : ''}
    >{`${percentage.toFixed(2)}%`}</TableBoldText>
  );
};

type BroadcastTableProps = {
  data: Broadcast[];
  sortData: TableSortData;
  onChange: TableProps<Broadcast>['onChange'];
};

export const BroadcastTable = memo<BroadcastTableProps>(
  ({ data, sortData = { key: undefined, direction: undefined }, onChange }) => {
    const { t } = useTranslation(['common', 'broadcast']);
    const { key: sortKey, direction: sortDirection } = sortData;
    const { formatSimpleRelativeDate, formatSimpleTime } = useFormatDate();

    const getTagConfig = useCallback(
      (status: BroadcastStatus) => {
        // All cases handled for fixed values
        // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
        return match(status)
          .with('SCHEDULED', () => ({
            color: 'secondaryGoldDark',
            border: 'secondaryGold60',
            background: 'secondaryGold10',
            label: t(
              'broadcast:pages.broadcast.broadcastTable.broadcastStatusBadge.scheduled',
            ),
          }))
          .with('SENT', () => ({
            color: 'secondaryMintDark',
            border: 'secondaryMint60',
            background: 'secondaryMint10',
            label: t(
              'broadcast:pages.broadcast.broadcastTable.broadcastStatusBadge.sent',
            ),
          }))
          .with('CANCELED', () => ({
            color: 'secondaryCoralDark',
            border: 'secondaryCoral60',
            background: 'secondaryCoral10',
            label: t(
              'broadcast:pages.broadcast.broadcastTable.broadcastStatusBadge.cancelled',
            ),
          }))
          .with(P.union('IN_PROGRESS_PREPARING', 'IN_PROGRESS_SENDING'), () => ({
            color: 'secondaryTopazDark',
            border: 'secondaryTopaz60',
            background: 'secondaryTopaz10',
            label: t(
              'broadcast:pages.broadcast.broadcastTable.broadcastStatusBadge.inProgress',
            ),
          }))
          .with('DRAFT', () => ({
            color: 'raven',
            border: 'casper',
            background: 'koala',
            label: t(
              'broadcast:pages.broadcast.broadcastTable.broadcastStatusBadge.draft',
            ),
          }))
          .exhaustive();
      },
      [t],
    );

    const columns: TableColumns<Broadcast>[] = [
      {
        title: (
          <ColumnHeaderText data-lgg-id="broadcast-table-column-header-broadcast">
            {t('broadcast:pages.broadcast.broadcastTable.columnHeaders.broadcast')}
          </ColumnHeaderText>
        ),
        key: 'name',
        wrapContent: true,
        showSorterTooltip: false,
        sorter: true,
        defaultSortOrder: getDefaultSortOrder(sortKey === 'name', sortDirection),
        render: (_, { name, status, isArchived }) => {
          const { label, ...tagTheme } = getTagConfig(status);

          return (
            <BroadcastDescriptionContainer data-lgg-id="broadcast-table-cell-name-and-status">
              <BroadcastTitle>{name}</BroadcastTitle>
              <FlexRow>
                <BroadcastStatusTag tagTheme={tagTheme} data-lgg-id="status-badge">
                  {label}
                </BroadcastStatusTag>
                {isArchived ? (
                  <BroadcastArchivedTag data-lgg-id="archived-badge">
                    {t('common:archived')}
                  </BroadcastArchivedTag>
                ) : null}
              </FlexRow>
            </BroadcastDescriptionContainer>
          );
        },
      },
      {
        title: (
          <ColumnHeaderText data-lgg-id="broadcast-table-column-header-start-date">
            {t('broadcast:pages.broadcast.broadcastTable.columnHeaders.startDate')}
          </ColumnHeaderText>
        ),
        key: 'startedAt',
        dataIndex: 'startedAt',
        width: '122px',
        showSorterTooltip: false,
        sorter: true,
        defaultSortOrder: getDefaultSortOrder(sortKey === 'startedAt', sortDirection),
        render: (startedAt: Date | null) => {
          return (
            <RowInfoContainer data-lgg-id="broadcast-table-cell-started-at">
              {startedAt ? (
                <>
                  <TableBoldText>{formatSimpleRelativeDate(startedAt)}</TableBoldText>
                  <TableLightText>{formatSimpleTime(startedAt)}</TableLightText>
                </>
              ) : (
                <></>
              )}
            </RowInfoContainer>
          );
        },
      },
      {
        title: (
          <ColumnHeaderText data-lgg-id="broadcast-table-column-header-audience">
            {t('broadcast:pages.broadcast.broadcastTable.columnHeaders.audience')}
          </ColumnHeaderText>
        ),
        key: 'audience',
        dataIndex: 'audience',
        width: '121px',
        showSorterTooltip: false,
        sorter: true,
        defaultSortOrder: getDefaultSortOrder(sortKey === 'audience', sortDirection),
        render: (audience: number) => (
          <TableLightText data-lgg-id="broadcast-audience">
            {audience ? audience.toLocaleString() : 0}
          </TableLightText>
        ),
      },
      {
        title: (
          <ColumnHeaderText data-lgg-id="broadcast-table-column-header-sent">
            {t('broadcast:pages.broadcast.broadcastTable.columnHeaders.sent')}
          </ColumnHeaderText>
        ),
        key: 'sent',
        dataIndex: 'sent',
        width: '81px',
        showSorterTooltip: false,
        render: (value: number, { status }) => {
          return shouldShowBroadcastDetail(status) ? (
            <TableLightText data-lgg-id="broadcast-table-cell-sent">
              {value}
            </TableLightText>
          ) : (
            emptyPlaceholder
          );
        },
      },
      {
        title: (
          <ColumnHeaderText data-lgg-id="broadcast-table-column-header-replies">
            {t('broadcast:pages.broadcast.broadcastTable.columnHeaders.replies')}
          </ColumnHeaderText>
        ),
        key: 'replies',
        dataIndex: 'replies',
        width: '112px',
        showSorterTooltip: false,
        render: (value: number, { status }) => {
          return shouldShowBroadcastDetail(status) ? (
            <RowInfoContainer data-lgg-id="broadcast-table-cell-replies">
              {getPercentageLabel(100, value)}
              <TableLightText>{value}</TableLightText>
            </RowInfoContainer>
          ) : (
            emptyPlaceholder
          );
        },
      },
      {
        title: (
          <ColumnHeaderText data-lgg-id="broadcast-table-column-header-opted-out">
            {t('broadcast:pages.broadcast.broadcastTable.columnHeaders.optedOut')}
          </ColumnHeaderText>
        ),
        key: 'optOuts',
        dataIndex: 'optOuts',
        width: '136px',
        showSorterTooltip: false,
        render: (optOuts: number, { status }) => {
          return shouldShowBroadcastDetail(status) ? (
            <RowInfoContainer data-lgg-id="broadcast-table-cell-opt-outs">
              {getPercentageLabel(100, optOuts, true)}
              <TableLightText>{optOuts > 0 ? optOuts : '-'}</TableLightText>
            </RowInfoContainer>
          ) : (
            emptyPlaceholder
          );
        },
      },
      {
        key: 'more-options',
        showSorterTooltip: false,
        width: '62px',
        wrapContent: true,
        render: (_, { status }) => {
          const isInProgress =
            status === 'IN_PROGRESS_PREPARING' || status === 'IN_PROGRESS_SENDING';

          return (
            <LggOptionsDropdownButtonWithCustomTriggerControlled
              customTrigger={
                <Icon type="moreOptions" lggTestId="table-cell-more-options" />
              }
              options={[
                ...(isInProgress
                  ? [
                      {
                        label: t('common:cancel'),
                        icon: 'close',
                        'data-lgg-id': 'broadcast-option-cancel',
                        // TODO: ADD PROPER ACTION
                        to: '/',
                      },
                    ]
                  : []),
                // TODO: Get conditions to delete
                {
                  label: t('common:delete'),
                  icon: 'deleteBin',
                  'data-lgg-id': 'broadcast-option-delete',
                  // TODO: ADD PROPER ACTION
                  to: '/',
                },
                // TODO: Get conditions to archive
                {
                  label: t('common:archive'),
                  icon: 'archive',
                  'data-lgg-id': 'broadcast-option-archive',
                  // TODO: ADD PROPER ACTION
                  to: '/',
                },
              ]}
            />
          );
        },
      },
    ];

    return (
      <Table
        rowKey={({ id }) => id}
        data-lgg-id="broadcast-table"
        columns={columns}
        tableLayout="fixed"
        loading={false}
        dataSource={data}
        pagination={false}
        showHeader={true}
        scroll={{ x: 1069 }}
        onChange={onChange}
        rowClassName={(record, index) =>
          `broadcast-table-row broadcast-row-${record.id} broadcast-row-position-${
            index + 1
          }`
        }
      />
    );
  },
);
